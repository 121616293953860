<template>
	<div>
		<span v-if="!isAdmin">欢迎使用</span>
		<template v-else>
			<div class="d-flex flex-row">
				<div class="my-card">
					<div class="marker">
						昨日利润
					</div>
					<div class="description">
						${{ l1dProfit }}
					</div>
				</div>
				<div class="my-card ml-2">
					<div class="marker">
						昨日收入
					</div>
					<div class="description">
						${{ l1dRevenue }}
					</div>
				</div>
			</div>
			<div class="d-flex flex-row my-2">
				<div class="my-card">
					<div class="marker">
						今日利润
					</div>
					<div class="description">
						${{ l0dProfit }}
					</div>
				</div>
				<div class="my-card ml-2">
					<div class="marker">
						今日收入
					</div>
					<div class="description">
						${{ l0dRevenue }}
					</div>
				</div>
			</div>
			<div class="d-flex flex-row">
				<div class="my-card">
					<div class="marker">
						本月利润
					</div>
					<div class="description">
						${{ l0mProfit }}
					</div>
				</div>
				<div class="my-card ml-2">
					<div class="marker">
						本月收入
					</div>
					<div class="description">
						${{ l0mRevenue }}
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import {fetchJSONByGet} from '@/apis/index.js'
	export default {
		data() {
			return {
				res: null
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user
			}),
			isAdmin() {
				const {superAdmin=false} = this.user || {}
				return superAdmin
			},
			l0dCost() {
			},
			l0dProfit() {
				const { l0dProfit = 0} = this.res || {}
				return l0dProfit.toFixed(2)
			},
			l0dRevenue() {
				const { l0dRevenue = 0} = this.res || {}
				return l0dRevenue.toFixed(2)
			},
			l0mCost() {
				
			},
			l0mRevenue() {
				const { l0mRevenue = 0} = this.res || {}
				return l0mRevenue.toFixed(2)
			},
			l0mProfit() {
				const { l0mProfit = 0} = this.res || {}
				return l0mProfit.toFixed(2)
			},
			l1dCost() {
				
			},
			l1dProfit() {
				const { l1dProfit = 0} = this.res || {}
				return l1dProfit.toFixed(2)
			},
			l1dRevenue() {
				const { l1dRevenue = 0} = this.res || {}
				return l1dRevenue.toFixed(2)
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (retries) => {
					fetchJSONByGet('/adms_api_v0/adscom/financialRecordReReport')
						.then(res => {
							this.res = res
						})
						.catch((err) => {
							if (retries > 0) {
								console.warn(`重试剩余重试次数: ${retries}`);
								fetchWithRetry(retries - 1); // 失败时递归重试
							} else {
								console.error(err);
							}
						});
				};
				// 调用重试方法获取资源
				fetchWithRetry(maxRetries);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.my-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex:1;
		height: 80px;
		padding: 5px;
		border: 1px solid rgba(0,0,0,0);
		border-radius: 10px;
		background-color: #fff;
		max-width: 160px;
		.marker {
			font-size: 10px;
		}
		.description {
			font-size: 22px;
			font-weight: 700;
		}
	}
</style>
