<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false" :picker-options="pickerOptions">
					</el-date-picker>
				</form-item>
				<form-item label="Site:" style="width:180px;">
					<el-input v-model="queryForm.site" size="mini"></el-input>
				</form-item>
				<form-item label="粒度:" style="width:180px;">
					<el-select v-model="queryForm.group" size="mini" class="w-100">
						<el-option label="聚合" :value="0"></el-option>
						<el-option label="按天" :value="1"></el-option>
					</el-select>
				</form-item>
				<account-select :accountName.sync="queryForm.accountName"></account-select>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<!-- <el-button type="primary" @click="showFilter" size="mini">过 滤</el-button> -->
				</form-item>
			</template>
			<template #advanced v-if="false">
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<el-table height="auto" ref="multipleTable" :data="filterData" size="mini" border class="jscx-pangle-site" @sort-change="sortChange">
			<el-table-column label="Date" prop="costDate" min-width="100" />
			<el-table-column label="Account Name" prop="accountName" min-width="150" />
			<el-table-column label="Pangle Site" prop="pangleSite" min-width="150" />
			<el-table-column label="Conversion" prop="conversion" min-width="150" sortable="custom" />
			<el-table-column label="Rev" prop="revenue" min-width="150" :formatter="cellFormatter" sortable="custom" />
			<el-table-column label="Epc" prop="epc" min-width="150" :formatter="cellFormatter" sortable="custom" />
		</el-table>
		<el-pagination class="mt-3" style="text-align:right;" :current-page.sync="page.current" :page-size.sync="page.size"
			:page-sizes="page.sizes" @size-change="page.current=1" layout="prev, pager, next, sizes, jumper" :total="tableData.length">
		</el-pagination>
	</el-card>
</template>

<script>
	import Moment from 'moment'
	import config from './minixs/pangle-site.js'
	import AccountSelect from '@/components/jscx/account-select.vue'
	export default {
		components: {
			AccountSelect
		},
		mixins: [config],
		data() {
			return {
				namespace: 'jscx',
				pickerOptions: {
					disabledDate(time) {
						const today = new Date();
						return time.getTime() > today; // 禁用不在范围内的日期
					}
				},
				sort: {
					prop: null,
					order: null
				}
			}
		},
		computed: {
			filterData() {
				return this.sortedData.slice((this.page.current-1)*this.page.size, this.page.current* this.page.size)
			},
			sortedData() {
				const {order, prop} = this.sort
				if (order === 'ascending' && prop !== null) {
					return this.tableData.slice().sort((a, b) => a[prop] - b[prop])
				} else if (order === 'descending' && prop !== null) {
					return this.tableData.slice().sort((a, b) => b[prop] - a[prop])
				}
				return this.tableData.slice()
			}
		},
		watch: {
			tableData(newValue, oldValue) {
				this.page.current = 1
			}
		},
		methods: {
			cellFormatter(row, column, cellValue, index) {
				if (column.property === 'revenue' || column.property === 'epc') {
					return cellValue.toFixed(2)
				}
			},
			sortChange({column, prop, order}) {
				this.sort.prop = prop
				this.sort.order = order
				console.log(column, prop, order)
			}
		}
	}
</script>

<style>
</style>