<template>
	<form-item label="Account Name:" style="width:300px;">
		<el-select v-model="localeAccount" clearable size="mini" filterable>
			<el-option v-for="account in accounts" :label="account.name" :value="account.name" :key="account.id"></el-option>
		</el-select>
	</form-item>
</template>

<script>
	export default {
		props: {
			accountName: {
				type: null|String,
				default: null
			},
			namespace: {
				type: String,
				default: 'jscx'
			}
		},
		data() {
			return {
				accounts: []
			}
		},
		computed: {
			localeAccount: {
				get() {
					return this.accountName
				},
				set(value) {
					this.$emit("update:accountName", value)
				}
			}
		},
		mounted() {
			this.initAccounts()
		},
		methods: {
			initAccounts() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, retries) => {
					this.refreshing = true
					this.$store.dispatch(`${this.namespace}/${action}`)
						.then(res => {
							this.accounts = res
						})
						.catch(() => {
							if (retries > 0) {
								console.warn(`重试剩余重试次数: ${retries}`);
								fetchWithRetry(action, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};
				// 调用重试方法获取资源
				fetchWithRetry('getAccounts', maxRetries);
			},
		}
	}
</script>

<style>
</style>