<template>
	<main-tabs :links="links" target="_blank">
		<router-view></router-view>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/jscx/data'
					},
					label: 'Data'
				}, {
					to: {
						path: '/jscx/terms'
					},
					label: 'Terms'
				}, {
					to: {
						path: '/jscx/plan'
					},
					label: 'Plan'
				}, {
					to: {
						path: '/jscx/offers'
					},
					label: 'CF Offers'
				}, {
					to: {
						path: '/jscx/term-adjust'
					},
					label: 'Term Adjust'
				}, {
					to: {
						path: '/jscx/domain-hour'
					},
					label: 'Domain Hour'
				}, {
					to: {
						path: '/jscx/pangle-site'
					},
					label: 'Pangle Site'
				}]
			}
		}
	}
</script>
