import Vue from 'vue'
import VueInstance from '../main.js'

function lookupPermission(route) {
	try {
		const {
			parent,
			meta
		} = route
		const {
			permission
		} = meta
		const foundPermission = permission || lookupPermission(parent)
		if (foundPermission) {
			return foundPermission
		}
	} catch (e) {
		console.error(e)
	}
}

function canAccess(user, path) {
	const {
		permissions = [], superAdmin = false
	} = user
	if (superAdmin) return true
	const {
		matched,
		meta: {
			permission
		},
		fullPath
	} = VueInstance.$router.match(path)
	var uncheckedPermission = permission || lookupPermission(matched[matched.length - 1])
	if (permissions && uncheckedPermission && permissions.find(p => p === uncheckedPermission) !== undefined) {
		return true
	}
	return false
}

Vue.directive('auth', {
	bind: function(el, binding, vnode) {
		const {
			to: {
				path
			}
		} = binding.value
		try {
			const hasPermission = canAccess(VueInstance.$store.state.user.user, path)
			if (hasPermission) {
				return
			}
			el.style.display = 'none'
		} catch (e) {
			console.info(e)
		}
	}
})

Vue.directive('access', {
	bind(el, binding, vnode) {
		function updateEl(el, binding) {
			try {
				const {
					permissions = [], superAdmin = false
				} = VueInstance.$store.state.user.user
				if (superAdmin) return;
				const {
					meta: {
						permission
					}
				} = binding.value
				const {
					u = false, a = false, d = false
				} = binding.modifiers
				const actionKey = u ? '[u]' : (a ? '[a]' : (d ? '[d]' : ''))
				const actionPermission = permission + actionKey
				const actionPermissions = permissions.filter(p => /[\[\]]/.test(p) !== false)
				const isAuth = actionPermissions.includes(actionPermission)
				if (!isAuth) {
					el.setAttribute('disabled', true)
					el.style.opacity = 0.5
				} else {
					el.style.opacity = 1
					el.setAttribute('disabled', false)
				}
			} catch (e) {
				console.log(e)
			}
		}
		vnode.context.$watch(() => VueInstance.$store.state.user.user, function(newVal, oldVal) {
			updateEl(el, binding);
		}, {immediate: true})
		updateEl(el, binding);
	}
});