<template>
	<el-card :class="fullScreen ? 'plan-full-screen': ''">
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false">
					</el-date-picker>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<el-button type="primary" @click="showFilter" size="mini">过 滤</el-button>
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
					<el-button type="primary" @click="showBatchConfirmDialog" size="mini" v-access.u="$route">批量确认</el-button>
					<el-button type="primary" @click="fullScreen=!fullScreen" size="mini">{{ fullScreen ? '缩小':'全屏'}}</el-button>
				</form-item>
			</template>
			<template #advanced v-if="false">
				<form-item label="AdsetIds">
					<el-select v-model="queryForm.adSetIds" filterable placeholder="请选择" size="mini" clearable multiple>
						<el-option v-for="item in resAdSetIds" :key="item.value" :label="item.value"
							:value="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="Adset Name" style="width:300px;">
					<el-autocomplete v-model="queryForm.adsetName" :fetch-suggestions="showAdsetNames" placeholder="请输入"
						size="mini" class="w-100"></el-autocomplete>
				</form-item>
				<form-item label="CampaignIds">
					<el-select v-model="queryForm.campaignIds" filterable placeholder="请选择" size="mini" clearable
						multiple>
						<el-option v-for="item in resCampaignIds" :key="item.value" :label="item.value"
							:value="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="Campaign Name" style="width:300px;">
					<el-autocomplete v-model="queryForm.campaignName" :fetch-suggestions="showCampaignNames"
						placeholder="请输入" size="mini" class="w-100"></el-autocomplete>
				</form-item>
				<form-item label="Channels">
					<el-select v-model="queryForm.channels" placeholder="请选择" multiple size="mini" clearable>
						<el-option v-for="channel in allChannels" :label="channel.toUpperCase()"
							:value="channel"></el-option>
					</el-select>
				</form-item>
				<form-item label="Domain Names">
					<el-input v-model="queryForm.domainNames" placeholder="请输入" size="mini" clearable></el-input>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<plan-table ref="planTable" class="w-100" :tableData="filterData" :tableColumns="filterTableColumns"
			:confirmPlan="confirmPlan" @handleSelectionChange="handleSelectionChange" @sortChange="sortChange" :sortBy="sort_by"></plan-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-drawer title="过滤" :visible.sync="drawer.filter" direction="rtl" :append-to-body="true">
			<div style="padding: 0 20px;">
				<div><small>AdSetIds:</small></div>
				<div>
					<el-select v-model="filter.adSetIds" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in adSetIds" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>AdSetNames:</small></div>
				<div>
					<el-select v-model="filter.adSetNames" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in adSetNames" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>CampaignIds:</small></div>
				<div>
					<el-select v-model="filter.campaignIds" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in campaignIds" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>CampaignNames:</small></div>
				<div>
					<template v-if="filter.campaignNameFuzzy">
						<el-input v-model="filter.campaignName" size="mini" placeholder=""
							style="width:179px;"></el-input>
					</template>
					<template v-else>
						<el-select v-model="filter.campaignNames" placeholder="" size="mini" filterable multiple>
							<el-option v-for="item in campaignNames" :label="item" :value="item"
								:key="item"></el-option>
						</el-select>
					</template>
					<el-switch style="margin-left:10px;" v-model="filter.campaignNameFuzzy" active-text="模糊搜索"
						size="mini">
					</el-switch>
				</div>
			</div>
		</el-drawer>
		<table-columns-drawer :visible.sync="drawer.edit" :data="sortedTableColumns" :checkedKeys.sync="checkedKeys"
			:sortedKeys.sync="sortedKeys" :namespace="namespace + '_plan'"
			:updateColumns="updateColumns"></table-columns-drawer>
		<el-dialog title="再次确认" :visible.sync="dialog.confirm" :append-to-body="true">
			<template v-if="selectedRow!==null">
				<table border>
					<tbody>
						<tr>
							<th width="15%">Campaign</th>
							<td width="60%">{{selectedRow.campaignName}}</td>
							<td width="25%">{{selectedRow.campaignId}}</td>
						</tr>
						<tr>
							<th>Adset</th>
							<td>{{selectedRow.adsetName}}</td>
							<td>{{selectedRow.adsetId}}</td>
						</tr>
						<tr>
							<th>Plan</th>
							<td colspan="2">{{selectedRow.plan}}</td>
						</tr>
					</tbody>
				</table>
			</template>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialog.confirm=false" size="mini">取 消</el-button>
				<el-button type="primary" @click="doubleConfirm" size="mini">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="再次确认" :visible.sync="dialog.batchConfirm" :append-to-body="true">
			<table border>
				<tbody v-for="row, index in multipleSelection" :key="index" class="mb-2">
					<tr>
						<th width="15%">Campaign</th>
						<td width="60%">{{row.campaignName}}</td>
						<td width="25%">{{row.campaignId}}</td>
					</tr>
					<tr>
						<th>Adset</th>
						<td>{{row.adsetName}}</td>
						<td>{{row.adsetId}}</td>
					</tr>
					<tr>
						<th>Plan</th>
						<td colspan="2">{{row.plan}}</td>
					</tr>
					<tr style="background:#ddd;height:5px;">
						<td colspan="3"></td>
					</tr>
				</tbody>
			</table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialog.batchConfirm=false" size="mini">取 消</el-button>
				<el-button type="primary" @click="batchConfirm" size="mini">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import PlanTable from '@/components/jscx/plan-table.vue'
	import config from './minixs/plan.js'
	import Moment from 'moment'
	import TableColumnsDrawer from '@/components/jscx/table-columns-drawer.vue'
	export default {
		mixins: [config],
		components: {
			PlanTable,
			TableColumnsDrawer
		},
		inject: ['adminLayout'],
		data() {
			return {
				fullScreen: false,
				timeout: null,
				sortedKeys: [],
				checkedKeys: [],
				namespace: 'jscx',
				drawer: {
					filter: false,
					edit: false
				},
				dialog: {
					confirm: false,
					batchConfirm: false
				},
				resources: {
					adsets: [],
					campaigns: []
				},
				filter: {
					adSetIds: [],
					adSetNames: [],
					campaignIds: [],
					campaignNames: [],
					campaignNameFuzzy: false,
					campaignName: ''
				},
				tableColumns: [
					{
						label: 'created At',
						prop: 'createdAt',
						disabled: false,
						sortable: false,
						width: 95
					}, {
						label: "Campaign ID",
						prop: "campaignId",
						disabled: false,
						sortable: false,
						width: 78
					},
					{
						label: "Campagin Name",
						prop: "campaignName",
						disabled: false,
						sortable: false,
						width: 80
					},
					{
						label: "Adset ID",
						prop: "adsetId",
						disabled: false,
						sortable: false,
						width: 78
					},
					{
						label: "Adset Name",
						prop: "adsetName",
						disabled: false,
						sortable: false,
						width: 85
					},
					{
						label: "cost",
						prop: "cost",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "budget",
						prop: "budget",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "conversions",
						prop: "conversions",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "ads-conversions",
						prop: "adsConversions",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "bid",
						prop: "bid",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "cpc",
						prop: "cpc",
						disabled: false,
						sortable: 'custom',
						width: 65
					},
					{
						label: "epc",
						prop: "epc",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "revenue",
						prop: "revenue",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: 'profit Rate',
						prop: 'profitRate',
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "L2d_profit rate",
						prop: "l2dProfitRate",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "create days",
						prop: "createDays",
						disabled: false,
						sortable: 'custom',
						width: 60
					},
					{
						label: "调整计划",
						prop: "plan",
						disabled: true,
						sortable: false
					},
					{
						label: "操作",
						prop: "status",
						disabled: true,
						sortable: false
					}
				],
				selectedRow: null,
				sortedTableColumns: [],
				filterTableColumns: []
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(`${this.namespace}_plan_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(`${this.namespace}_plan_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
			this.updateColumns()
		},
		mounted() {
			this.initResouces()
		},
		computed: {
			filterData() {
				return this.tableData.reduce((lhs, rhs) => {
					const res1 = this.filter.adSetIds.length === 0 || this.filter.adSetIds.length > 0 && this
						.filter.adSetIds.includes(rhs.adsetId)
					const res2 = this.filter.adSetNames.length === 0 || this.filter.adSetNames.length > 0 && this
						.filter.adSetNames.includes(rhs.adsetName)
					const res3 = this.filter.campaignIds.length === 0 || this.filter.campaignIds.length > 0 && this
						.filter.campaignIds.includes(rhs.campaignId)
					const res4 = this.filter.campaignNameFuzzy ? (new RegExp(this.filter.campaignName, "ig")).test(
						rhs.campaignName) : (this.filter.campaignNames.length === 0 || this.filter
						.campaignNames.length > 0 && this.filter.campaignNames.includes(rhs.campaignName))
					if (res1 && res2 && res3 && res4) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			placements() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.placement && !lhs.includes(rhs.placement)) {
						lhs.push(rhs.placement)
					}
					return lhs
				}, [])
			},
			adSetIds() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.adsetId && !lhs.includes(rhs.adsetId)) {
						lhs.push(rhs.adsetId)
					}
					return lhs
				}, [])
			},
			adSetNames() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.adsetName && !lhs.includes(rhs.adsetName)) {
						lhs.push(rhs.adsetName)
					}
					return lhs
				}, [])
			},
			campaignIds() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.campaignId && !lhs.includes(rhs.campaignId)) {
						lhs.push(rhs.campaignId)
					}
					return lhs
				}, [])
			},
			campaignNames() {
				return this.tableData.reduce((lhs, rhs) => {
					if (rhs.campaignName && !lhs.includes(rhs.campaignName)) {
						lhs.push(rhs.campaignName)
					}
					return lhs
				}, [])
			},
			resAdSetIds() {
				return this.resources.adsets.map(v => {
					return {
						value: v.id
					}
				})
			},
			resAdSetNames() {
				return this.resources.adsets.map(v => {
					return {
						value: v.name
					}
				})
			},
			resCampaignIds() {
				return this.resources.campaigns.map(v => {
					return {
						value: v.id
					}
				})
			},
			resCampaignNames() {
				return this.resources.campaigns.map(v => {
					return {
						value: v.name
					}
				})
			}
		},
		watch: {
			tableData(newValue, oldValue) {
				this.filter.adSetIds = []
				this.filter.adSetNames = []
				this.filter.campaignIds = []
				this.filter.campaignNames = []
			},
			fullScreen(newVal, oldVal) {
				this.$nextTick(() => {
					this.$refs.planTable.doLayout()
				})
			}
		},
		methods: {
			showBatchConfirmDialog() {
				if (this.multipleSelection.length === 0) {
					this.$showErrMsg('您没选中任一计划')
					return
				}
				this.dialog.batchConfirm = true

			},
			batchConfirm() {
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/batchConfirmJSCXPlans`, this.ids)
					.then(res => {
						this.$refs.planTable.$refs.multipleTable.clearSelection()
						this.dialog.batchConfirm = false
						this.adminLayout.hideLoading()
						this.searchEvent(false)
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
			},
			updateColumns() {
				this.sortedTableColumns = this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
				this.filterTableColumns = this.sortedTableColumns.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			initResouces() {
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, resourceKey, retries) => {
					this.$store.dispatch(`${this.namespace}/${action}`)
						.then(res => {
							this.resources[resourceKey] = res; // 成功时设置资源
						})
						.catch(() => {
							if (retries > 0) {
								console.warn(`重试 ${resourceKey}，剩余重试次数: ${retries}`);
								fetchWithRetry(action, resourceKey, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};

				// 调用重试方法获取资源
				fetchWithRetry('getCampaigns', 'campaigns', maxRetries);
				fetchWithRetry('getAdsets', 'adsets', maxRetries);
			},
			resetAndReload() {
				this.tableData = []
				this.searchEvent()
			},
			showFilter() {
				this.drawer.filter = true
			},
			showEdit() {
				this.drawer.edit = true
			},
			createStateFilter(queryString) {
				return (state) => {
					return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
				};
			},
			showAdsetNames(queryString, cb) {
				var names = this.resAdSetNames;
				var results = queryString ? names.filter(this.createStateFilter(queryString)) : names;
				cb(results);
			},
			showCampaignNames(queryString, cb) {
				var names = this.resCampaignNames;
				var results = queryString ? names.filter(this.createStateFilter(queryString)) : names;
				cb(results);
			},
			confirmPlan(row) {
				this.selectedRow = row
				this.dialog.confirm = true
			},
			doubleConfirm() {
				this.dialog.confirm = false
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/confirmJSCXPlan`, this.selectedRow.id)
					.then(res => {
						this.adminLayout.hideLoading()
						this.selectedRow.status = 1
						try {
							this.$showSuccess('request_id:' + res.request_id + ' ' + res.message)
						} catch (err) {}

					})
					.catch(error => {
						this.adminLayout.hideLoading()
						this.$showErrMsg(error)
					})
			},
			sortChange({column, prop, order}) {
				this.sort_by = order !== null ? {orderField: prop, orderDirection: order === 'ascending' ? 'ASC' : 'DESC'} : null
				this.searchEvent()
			}
		}
	}
</script>

<style>
	.plan-full-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
</style>