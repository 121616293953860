<template>
	<main-container>
		<el-result v-if="hasErr" icon="error" title="错误提示" subTitle="网络错误,请稍后重试">
			<template slot="extra">
				<el-button type="primary" size="medium" @click="__init()">刷新</el-button>
			</template>
		</el-result>
		<template v-else>
			<el-breadcrumb slot="header-title" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/settings/account' }" :replace="true">账号管理</el-breadcrumb-item>
				<el-breadcrumb-item>{{isNewUser ? '添加' : '编辑'}}</el-breadcrumb-item>
			</el-breadcrumb>
			<el-button slot="actions" type="primary" @click="clickSave" size="mini">保存</el-button>
			<el-card style="height:calc(100% - 80px);">
				<div style="height:100%;overflow-y:auto;padding-right:20px;">
					<el-form ref="form" :model="form" label-width="80px" :hide-required-asterisk="true" :rules="rules">
						<el-form-item label="用户名" prop="name">
							<el-input v-model.trim="form.name"></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="email">
							<el-input v-model.trim="form.email"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="phone">
							<el-input v-model.trim="form.phone"
								@input="form.phone=form.phone.replace(/[^\d]/g, '')"></el-input>
						</el-form-item>
						<el-form-item v-if="isNewUser" label="密码" prop="password">
							<el-input v-model.trim="form.password" show-password></el-input>
						</el-form-item>
						<div v-if="!form.superAdmin" class="d-flex flex-column">
							<div>
								<el-form-item label="权限" class="checkbox-group-form-item">
									<el-tree ref="permission-tree" class="account-tree" :data="permissions"
										show-checkbox node-key="value" default-expand-all @check="permissionCheck"
										:props="defaultProps">
										<template slot-scope="{ node, data }">
											<div v-if="node.isLeaf" class="d-flex flex-row align-items-center justify-content-between w-100">
												<small>{{ node.label }}({{ node.key }})</small>
												<div v-if="Array.isArray(data.access) && data.access.length > 0" style="margin-left:auto;">
													<el-checkbox v-for="item in data.access" :label="node.key + '['+ item +']'" size="mini" v-model="form.actionPermissions" :key="node.key + '[' + item + ']'">
														
														<small v-if="item==='a'" >Add</small>
														<small v-else-if="item==='u'">Update</small>
														<small v-else-if="item==='d'">Delete</small>
													</el-checkbox>
												</div>
											</div>
											<small v-else>
											{{ node.label }}({{ node.key }})
											</small>
										</template>
									</el-tree>
								</el-form-item>
							</div>
							<div v-show="false">
								<el-form-item label="站点" class="checkbox-group-form-item" prop="channels">
									<el-tree ref="website-tree" class="account-tree" :data="channels" show-checkbox
										node-key="value" default-expand-all @check="websiteCheck" :props="defaultProps">
										<small slot-scope="{ node, data }">{{ node.label }}({{ node.key }})</small>
									</el-tree>
								</el-form-item>
							</div>
							<div>
								<el-form-item label="域名" class="checkbox-group-form-item" prop="domains">
									<el-tree ref="domain-tree" class="account-tree" :data="domains" show-checkbox
										node-key="value" default-expand-all @check="domainCheck" :props="defaultProps">
										<small slot-scope="{ node, data }">{{ node.label }}</small>
									</el-tree>
								</el-form-item>
							</div>
						</div>
					</el-form>
				</div>
			</el-card>
			<el-button v-if="!isNewUser && !form.superAdmin" class="mt-4" type="danger"
				@click="clickDelAction">删除用户</el-button>
		</template>
	</main-container>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import {
		mapActions,
		mapState
	} from 'vuex'
	import Lodash from 'lodash'
	import Config from '@/common/config/config.js'
	import {
		fetchJSONByApis
	} from '@/apis'
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		props: ['id'],
		data() {
			return {
				hasErr: false,
				form: {
					name: '',
					email: '',
					password: '',
					channels: [],
					permissions: [],
					phone: '',
					domains: [],
					actionPermissions: []
				},
				checkAll: {
					permissions: false,
					channels: false,
				},
				isIndeterminate: {
					permissions: false,
					channels: false
				},
				permissions: [],
				rules: {
					name: [{
						required: true,
						message: '用户名不能为空'
					}],
					email: [{
						required: true,
						message: '邮箱不能为空'
					}, {
						type: 'email',
						message: '邮箱格式不正确'
					}],
					phone: [{
						required: true,
						message: '手机号不能为空'
					}],
					password: [{
						validator: (rule, value, callback) => {
							if (value.length === 0) {
								callback('密码不能为空')
							} else if (value.length > 1 && value.length < 8) {
								callback('密码长度至少8位')
							} else {
								callback()
							}
						}
					}],
					channels: [{
						required: false,
						message: '站点必须选择一个',
						type: "array"
					}]
				},
				channels: [],
				domains: [],
				defaultProps: {
					children: 'children',
					label: 'text'
				},
				allPermissions: []
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user
			}),
			isNewUser() {
				return /^\d+$/.test(this.id) === false
			}
		},
		mounted() {
			this.loadPermissionsFromRouter()
			if (this.id !== 'add' && /^\d+$/.test(this.id) === false) {
				this.nav_replace({
					name: 'notfound'
				})
				return
			}
			this.__init()
		},
		methods: {
			...mapActions([
				'fetchAllChannels',
				'getUser',
				'createUser',
				'updateUser',
				'deleteUser',
				'logout'
			]),
			loadPermissionsFromRouter() {
				// {text, value}
				const routes = this.$router.options.routes[1].children
				this.permissions = [{
					value: 'ALL',
					text: '全选',
					children: this.loadRouter(routes, '', this.permissions)
				}]
			},
			loadRouter(routes = [], preText = '') {
				const list = []
				routes.forEach(v => {
					try {
						const {
							children,
							meta
						} = v
						const {
							permission,
							title,
							access=[]
						} = meta || {}
						if (permission && title) {
							var text = title
							if (!!preText) {
								text = preText + '-' + title
							}
							const curEl = {
								value: permission,
								text,
								access
							}
							this.allPermissions.push(permission)
							list.push(curEl)
							if (Array.isArray(children) && children.length > 0) {
								curEl.children = this.loadRouter(children, text)
							}
						}
					} catch (e) {
						console.log(v)
						console.error(e)
					}
				})
				return list
			},
			__init() {
				this.hasErr = false
				const apis = []
				apis.push(this.fetchAllChannels())
				apis.push(this.$store.dispatch('jscx/getJSCXDomains'))
				if (this.id !== 'add') {
					apis.push(this.getUser(this.id))
				}
				this.adminLayout.showLoading()
				fetchJSONByApis(...apis)
					.then(([channels, domains, user]) => {
						this.channels = [{
							text: '全部',
							value: 'ALL',
							children: channels.map(({
								code,
								name
							}) => {
								return {
									value: name,
									text: code
								}
							})
						}]
						this.domains = [{
							text: '全部',
							value: 'ALL',
							children: Object.keys(domains).map((term) => {
								const termDomains = domains[term] || []
								return {
									value: term,
									text: term,
									children: termDomains.map(domain => {
										return {
											value: domain,
											text: domain
										}
									})
								}
							})
						}]
						if (user) {
							const permissions = (user.permissions || []).filter(v => /[\[\]]/.test(v) === false)
							const actionPermissions = (user.permissions || []).filter(v => /[\[\]]/.test(v) !== false)
							const uChannels = user.channels || []
							const uDomains = (user.domains || []).map(v => v.name)
							this.form = {
								...this.form,
								...user,
								permissions,
								channels: uChannels,
								domains: uDomains,
								actionPermissions
							}
							this.$refs['permission-tree'].setCheckedKeys(permissions.filter(p => {
								const node = this.$refs['permission-tree'].getNode(p)
								if (node) {
									return node.isLeaf
								}
								return false
							}))
							this.$nextTick(() => {
								this.$refs['domain-tree'].setCheckedKeys(uDomains.filter(p => {
									const node = this.$refs['domain-tree'].getNode(p)
									if (node) {
										return node.isLeaf
									}
									return false
								}))
							})
							this.$refs['website-tree'].setCheckedKeys(uChannels)
						}
						this.adminLayout.hideLoading()
					})
					.catch(err => {
						this.hasErr = true
						this.adminLayout.hideLoading()
					})
			},
			isValidDomain(domain) {
				var regex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i;
				return regex.test(domain);
			},
			clickSave() {
				this.$refs.form.validate(v => {
					if (v) {
						const api = !this.isNewUser ? this.updateUser : this.createUser
						const permissions = this.form.permissions.filter(v => v !== 'ALL')
						const channels = this.form.channels.filter(v => v !== 'ALL')
						const domains = this.form.domains.filter(v => v !== 'ALL').map(name => {
							const type = this.isValidDomain(name) ? 1 : 0
							return {
								type,
								name
							}
						})
						const body = !this.isNewUser ? {
							id: this.id,
							body: {
								...this.form,
								permissions: [...permissions, ...this.form.actionPermissions],
								channels,
								domains
							}
						} : {
							...this.form,
							permissions: [...permissions, ...this.form.actionPermissions],
							channels,
							domains
						}
						this.adminLayout.showLoading()
						api(body)
							.then(res => {
								this.adminLayout.hideLoading()
								this.$showSuccess(this.id ? '更新成功' : '创建成功')
								this.nav_replace('settings_account')
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					}
				})
			},
			clickDelAction() {
				this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.deleteUser(this.id)
						.then(res => {
							this.adminLayout.hideLoading()
							if (this.id === this.user.id) {
								this.logout()
									.then(() => {
										this.nav_replace('login')
									})
							} else {
								this.nav_replace('settings_account')
							}
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
				})
			},
			permissionCheck(node, {
				checkedKeys,
				checkedNodes,
				halfCheckedKeys,
				halfCheckedNodes
			}) {
				this.form.permissions = [...checkedKeys, ...halfCheckedKeys]
			},
			websiteCheck(node, {
				checkedKeys,
				checkedNodes,
				halfCheckedKeys,
				halfCheckedNodes
			}) {
				this.form.channels = checkedKeys
			},
			domainCheck(node, {
				checkedKeys,
				checkedNodes,
				halfCheckedKeys,
				halfCheckedNodes
			}) {
				this.form.domains = [...checkedKeys]
			}
		}
	}
</script>

<style lang="scss">
	.checkbox-group-form-item {
		margin-bottom: 0;

		.el-form-item__label {
			line-height: 24px;
			margin-bottom: 0;
		}

		.el-form-item__content {
			line-height: 24px;
		}
	}

	.account-tree {
		.el-checkbox {
			margin-bottom: 0;
		}
	}
</style>